<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title class="px-3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              v-bind="attrs"
              v-on="on"
              text
              min-width="48"
              class="mr-1 mb-0 px-3 d-inline-flex"
              @click="pesquisar"
            >
              <v-icon color="primary">mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Atualizar Lista</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn-toggle v-model="refreshList" color="primary" dense>
              <v-btn
                outlined
                v-bind="attrs"
                v-on="on"
                class="mr-1 mb-1"
                :value="true"
              >
                <v-icon :color="refreshList ? 'primary' : 'grey'"
                  >mdi-history</v-icon
                >
              </v-btn>
            </v-btn-toggle>
          </template>
          <span
            >{{ refreshList ? "Desativar" : "Ativar" }} atualização automática
            ( Atualiza a lista a cada 60 segundos )</span
          >
        </v-tooltip>
        <v-tooltip bottom close-delay="100">
          <template v-slot:activator="{ on, attrs }">
            <v-btn-toggle
              @change="changeStatusFilterBottom"
              v-model="statusFilterBottom"
              class="d-inline-block"
              color="primary"
              multiple
              dense
            >
              <v-btn outlined v-bind="attrs" v-on="on" class="mr-1 mb-1" width="110" :class="statusFilterBottom.indexOf(0) > -1 ? '' : 'orange white--text'">
                <span>PENDENTE</span>
              </v-btn>
              <v-btn outlined v-bind="attrs" v-on="on" class="mr-1 mb-1" width="110" :class="statusFilterBottom.indexOf(1) > -1 ? '' : 'green white--text'">
                <span>CONFIRMADO</span>
              </v-btn>
              <v-btn outlined v-bind="attrs" v-on="on" class="mr-1 mb-1" width="110" :class="statusFilterBottom.indexOf(2) > -1 ? '' : 'red white--text'">
                <span>CANCELADO</span>
              </v-btn>
              <v-btn outlined v-bind="attrs" v-on="on" class="mr-1 mb-1" width="110" :class="statusFilterBottom.indexOf(3) > -1 ? '' : 'primary white--text'">
                <span>ATENDIDO</span>
              </v-btn>
            </v-btn-toggle>
          </template>
          <span>Filtrar situações</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip top v-if="selected.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              class="mr-2 mb-1"
              ripple
              color="primary"
              icon="icon"
              slot="activator"
              @click="dialog = true"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Excluir Selecionado(s)</span>
        </v-tooltip>
        <v-tooltip top v-if="selected.length == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              class="mr-2 mb-1"
              ripple
              color="primary"
              icon="icon"
              @click="editar"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar Selecionado</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="dialogTeste"
              outlined
              ripple
              color="primary"
              @click="novo"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="ml-1">mdi-plus</v-icon> Adicionar Agendamento
            </v-btn>
          </template>
          <span>Adicionar Novo</span>
        </v-tooltip>
      </v-card-title>

      <v-card-subtitle>
        <v-row class="pt-1">

          <v-chip v-for="(f, i) in favoritos" :key="i"
            v-model="favoritos"
            class="ml-2"
            :class="f.active"
            @click="executarFavorito(f)"
            style="min-width: 150px"
            small
          >
            <v-avatar left>
              <v-icon>mdi-star-circle</v-icon>
            </v-avatar>
            {{ f.nome }}
          </v-chip>

        </v-row>
      </v-card-subtitle>

      <v-container fluid>
        <v-data-table
          v-bind:headers="headers"
          :items="items"
          v-model="selected"
          seleacted-key="id"
          show-select
          single-select
          mobile-breakpoint="700"
          id="agendamento-table-item"
          :item-class="getRowClassItem"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50, 100] }"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          :search="searche"
        >
          <template v-slot:[`item.paciente.nome`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <a v-bind="attrs" v-on="on" link @click="linhaClicada(item)" :class="$vuetify.theme.isDark ? 'white--text' : 'black--text'">
                  {{ $_.startCase(item.paciente.nome.toLowerCase()) }}
                </a>
              </template>
              <span>Abrir Pré-Atendimento</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.dataConsulta`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-on="!isDateToday(item.dataConsulta) ? on : false" v-bind="attrs">
                  {{ formatDataConsulta(`${item.dataConsulta}Z`) }}
                </span>
              </template>
              <span> {{formatDataConsultaDisplay(item.dataConsulta)}} </span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.paciente.cpf`]="{ item }">
            <span>{{
              item.paciente.cpf.substring(0, 5) +
              item.paciente.cpf.substring(5).replace(/[0-9]/g, "*")
            }}</span>
          </template>

          <template v-slot:[`item.statusAgendamento.descricao`]="{ item }">
            <v-chip
              label
              dark
              small
              style="width: 100px"
              class="mr-2 justify-center"
              :color="statusColor(item.statusAgendamento.descricao)"
            >
              <span>{{ item.statusAgendamento.descricao }}</span>
            </v-chip>
          </template>

          <template v-slot:[`item.acao`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" 
                  light
                  x-small 
                  class="text-capitalize"
                  id="item-actions-botton"
                >Ações</v-btn>
              </template>
              <v-list light rounded outlined elevation="2">
                <v-list-item dense class="px-0">
                  <v-list-item-action class="ma-0" style="align-items: flex-start">
                    <v-btn small plain class="text-capitalize" @click="linhaClicada(item)">Pré Atendimento</v-btn>
                    <v-btn small left plain class="text-capitalize" @click="linkTeleconsulta(item.atendimentoAssociado, item.id)"
                      v-if="(possuiPermissaoAtendente || possuiPermissaoAdmin) && 
                        item.statusAgendamento.descricao == 'CONFIRMADO' && 
                        item.atendimentoAssociado != null && 
                        !item.atendimentoAssociado.atendimentoAtendidoExpirado"
                    >Links</v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <v-col cols="12" md="4">
          <v-dialog
            v-model="registroPreAtendimentoComponentOpts.dialogPreAtendimento"
            persistent
            width="95%"
            scrollable
          >
            <RegistroPreAtendimentoComponent
              :isModal="true"
              :idParametter="agendamentoSelecionado.preAtendimento.id"
              :idAgendamento="agendamentoSelecionado.id"
              :agendamentoSelecionado="agendamentoSelecionado"
              :pacientePreAtendimento="agendamentoSelecionado.paciente"
              :agendamentoUnidadeId="agendamentoSelecionado.unidade.id"
              v-if="registroPreAtendimentoComponentOpts.showPreAtendimentoForm"
              @cancel="triggerRegistroPreAtendimentoComponent($event, 'cancel')"
              @save="triggerRegistroPreAtendimentoComponent($event, 'saved')"
            />
          </v-dialog>
        </v-col>
      </v-container>

      <v-dialog v-model="dialog" persistent max-width="450">
        <v-card>
          <v-card-title>{{ confirm.title }}</v-card-title>
          <v-card-text>{{ confirm.contentHtml }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="red--text darken-1"
              @click.native="dialog = false"
              >{{ confirm.cancel }}</v-btn
            >
            <v-btn
              text
              class="green--text darken-1"
              @click.native="excluir()"
              >{{ confirm.ok }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <alerta></alerta>

      <v-dialog
        v-model="dialogTeste"
        top
        content-class="my-custom-dialog"
        max-width="80%"
        hide-overlay
        transition="fab-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Filtros da pesquisa</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="fecharDialogFiltroPesquisa">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-container fluid>
            <v-row>
              <v-col md="6" cols="12">
                <v-autocomplete
                  rounded
                  filled
                  v-model="filtroPesquisa.periodo"
                  @change="periodoDeTempoChange"
                  :items="periodosDeTempo"
                  label="Periodo de Tempo"
                  item-text="nome"
                  item-value="id"
                  return-object
                  chips
                  deletable-chips
                ></v-autocomplete>
              </v-col>
              <v-col md="3" cols="12">
                <v-menu
                  ref="show_start_date"
                  :close-on-content-click="false"
                  v-model="show_start_date"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="start_date"
                      label="De"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_dateFormatada"
                    @input="show_start_date = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col md="3" cols="12">
                <v-menu
                  ref="show_end_date"
                  :close-on-content-click="false"
                  v-model="show_end_date"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      v-model="end_date"
                      label="Ate"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_dateFormatada"
                    @input="show_end_date = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <v-text-field
                  v-model="filtroPesquisa.nomePacienteElemento"
                  append-icon="search"
                  label="Nome"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col md="3" cols="12">
                <v-text-field
                  append-icon="search"
                  label="CPF"
                  single-line
                  hide-details
                  v-model="filtroPesquisa.cpfPacienteForm"
                  v-mask="'###.###.###-##'"
                  placeholder="999.999.999-99"
                ></v-text-field>
              </v-col>

              <v-col md="3" cols="12">
                <v-autocomplete
                  :items="unidades"
                  label="Unidade"
                  item-text="nome"
                  item-value="id"
                  return-object
                  v-model="filtroPesquisa.unidadePacienteForm"
                  chips
                  multiple
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      close
                      small
                      @click:close="
                        filtroPesquisa.unidadePacienteForm.splice(
                          filtroPesquisa.unidadePacienteForm.indexOf(item),
                          1
                        )
                      "
                    >
                      <span>{{ item.nome }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col md="6" cols="12">
                <v-autocomplete
                  :items="statuss"
                  label="Status"
                  item-text="nome"
                  item-value="id"
                  return-object
                  v-model="filtroPesquisa.statusPacienteForm"
                  multiple
                  chips
                  deletable-chips
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="pr-4">
            <v-spacer></v-spacer>
            <v-chip
              v-if="isFavorited"
              class="ma-2 pr-4 elevation-2"
              color="teal"
              text-color="white"
            >
              <v-avatar left>
                <v-icon>mdi-star-circle</v-icon>
              </v-avatar>
              {{ favoritoAtual.nome }}
            </v-chip>
            <v-btn icon>
              <v-tooltip bottom v-if="!isFavorited">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    x-large
                    dark
                    @click="openDialogFavoritarFiltroPesquisa"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-star-plus-outline</v-icon
                  >
                </template>
                <span>Adicionar este filtro aos Favoritos</span>
              </v-tooltip>

              <v-icon
                x-large
                dark
                v-if="
                  isFavorited &&
                  (favoritoMouseEvent === null || favoritoMouseEvent === -1)
                "
                @click="isFavorited = !isFavorited"
                @mouseover="favoritoMouseEvent=0"
                @mouseleave="favoritoMouseEvent=-1"
              >
                mdi-star-check
              </v-icon>

              <v-tooltip bottom v-if="isFavorited && favoritoMouseEvent === 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    x-large
                    dark
                    @click="removerFavoritosFiltroPesquisa"
                    v-bind="attrs"
                    v-on="on"
                    @mouseleave="favoritoMouseEvent=-1"
                    >mdi-star-remove</v-icon
                  >
                </template>
                <span>Remover filtro dos Favoritos</span>
              </v-tooltip>
            </v-btn>
            <v-btn color="secondary" @click="limpar"> Limpar </v-btn>
            <v-btn color="primary" @click="pesquisar"> buscar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogFavorito" persistent  max-width="450">
        <v-card>
            <v-card-title>Informe o nome do Favorito</v-card-title>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="favoritoForm.nome"
                    label="Nome"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="red--text darken-1" @click.native="dialogFavorito = false">Cancelar</v-btn>
            <v-btn text class="green--text darken-1" @click.native="favoritarFiltroPesquisa">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-dialog
      v-model="linkTeleConsultaComponent.dialogLinkTeleConsulta"
      persistent
    >
      <v-card>
        <v-card-title>MEZZOW TELECONSULTA</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-data-table
                v-bind:headers="headersPaciente"
                v-bind:items="pacientesLink"
                hide-default-footer
              >
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              ID MEZZOW TELECONSULTA:
              {{ atendimentoSelecionado.idTeleConsulta }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="1"> Link Paciente: </v-col>
            <v-col>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    right
                    outlined
                    class="mr-2"
                    ripple
                    color="primary"
                    :href="atendimentoSelecionado.linkTeleConsultaPaciente"
                    target="_blank"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mr-2">mdi-link-box-variant-outline</v-icon>
                    Clique Aqui
                  </v-btn>
                </template>
                <span>Abrir Link do Paciente</span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="12"
              md="1"
              v-if="atendimentoSelecionado.linkFormulario"
            >
              Link Formulario:
            </v-col>
            <v-col v-if="atendimentoSelecionado.linkFormulario">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    right
                    outlined
                    class="mr-2"
                    ripple
                    color="primary"
                    :href="atendimentoSelecionado.linkFormulario"
                    target="_blank"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mr-2">mdi-link-box-variant-outline</v-icon>
                    Clique Aqui
                  </v-btn>
                </template>
                <span>Abrir Link do Formulario</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="1"> Link Médico </v-col>
            <v-col>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    right
                    outlined
                    class="mr-2"
                    ripple
                    color="primary"
                    :href="atendimentoSelecionado.linkTeleConsultaMedico"
                    target="_blank"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mr-2">mdi-link-box-variant-outline</v-icon>
                    Clique Aqui
                  </v-btn>
                </template>
                <span>Abrir Link do Médico</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            right
            outlined
            class="mr-2"
            color="primary"
            @click.native="
              linkTeleConsultaComponent.dialogLinkTeleConsulta = false
            "
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Alerta from "@/components/comum/Alerta.vue";
import { mapActions, mapGetters } from "vuex";
import { bus } from "../../main";
import RegistroPreAtendimentoComponent from "@/views//atendimento/RegistroPreAtendimentoCreateEdit.vue";
import moment from "moment";
import { possuiPermissao, getUserPreferences } from "../../seguranca";

export default {
  name: "agendamento-list",
  data() {
    return {
      clicouLimpar: false,
      dialogFavorito:false,
      favoritoForm:{nome:''},
      colorsFavoritos: ['green', 'purple', 'orange', 'indigo', 'red'],
      favoritoMouseEvent: null,
      isFavorited: false,
      favoritoAtual: null,
      favoritos: [],
      filtroPesquisa: {
        periodo: {
          id: "",
        },
      },
      statusFilterBottom: [],
      refreshList: undefined,
      refreshInterval: null,
      periodosDeTempo: [
        { nome: "PRÓXIMOS 30 DIAS", id: "PROXIMOS_30_DIAS" },
        { nome: "PRÓXIMOS 7 DIAS", id: "PROXIMOS_07_DIAS" },
        { nome: "AMANHÃ", id: "AMANHA" },
        { nome: "HOJE", id: "HOJE" },
        { nome: "ONTEM", id: "ONTEM" },
        { nome: "ÚLTIMOS 3 DIAS", id: "ULTIMOS_03_DIAS" },
        { nome: "ÚLTIMOS 7 DIAS", id: "ULTIMOS_07_DIAS" },
        { nome: "ÚLTIMOS 30 DIAS", id: "ULTIMOS_30_DIAS" },
      ],
      atendimentoSelecionado: {
        registroPosConsulta: {},
      },
      linkTeleConsultaComponent: {
        showLinkTeleConsulta: false,
        dialogLinkTeleConsulta: false,
      },
      periodo: null,
      txtFilter: "",
      nomePacienteElemento: "",
      agendamentoSelecionado: {
        preAtendimento: {},
      },
      registroPreAtendimentoComponentOpts: {
        showPreAtendimentoForm: false,
        dialogPreAtendimento: false,
        lastPreAtendimentoEvent: "",
        lastPreAtendimentoDados: {},
      },
      dialogTeste: false,
      notifications: false,
      sound: true,
      widgets: false,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      weekdays: [1, 2, 3, 4, 5 /*, 6, 0*/],
      focus: "",
      type: "week",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],

      nomePacienteForm: "",
      cpfPacienteForm: "",
      statusPacienteForm: "",
      unidadePacienteForm: "",

      show_start_date: false,
      start_date: null,
      start_dateFormatada: null,
      show_end_date: false,
      end_date: null,
      end_dateFormatada: null,
      filterss: "",
      filters: {
        nome: "",
        cpf: "",
        unidade: "",
        status: "",
        start_date: null,
        end_date: null,
      },
      pagination: {
        sortBy: "id",
      },
      unidades: [],
      pacientes: [],
      pacientesUnmodified: [],
      statuss: [
        { nome: "PENDENTE", id: "2" },
        { nome: "CONFIRMADO", id: "3" },
        { nome: "CANCELADO", id: "4" },
        { nome: "ATENDIDO", id: "5" },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["dataConsulta"],
        sortDesc: [false],
      },
      camposFiltro: [
        { value: "nomeUnidade", text: "Unidade" },
        { value: "nomePaciente", text: "Paciente" },
        { value: "cpfPaciente", text: "Paciente" },
        { value: "dataConsulta", text: "Data" },
        { value: "statusAgendamento", text: "Grupo Empresa" },
      ],
      campoFiltro: "nome",
      mapFieldNamesForSorting: {
        permissaoForView: "permissaoForView",
        username: "username",
        "u.username": "u.username",
      },
      loading: true,
      loadingPaciente: true,
      items: [],
      itemsUnmodified: [],
      modalidades: [],
      errors: [],
      total: null,
      titulo: "Agendamentos",
      confirm: {
        title: "Atenção",
        contentHtml:
          "Tem certeza que deseja excluir o(s) usuário(s) selecionado(s).",
        ok: "Sim",
        cancel: "Não",
      },
      dialog: false,
      snackbar: false,
      tipoAlerta: "",
      timeoutAlerta: 6000,
      textAlerta: "",
      searche: "",
      selected: [],
      selectAll: "",
      headers: [
        {
          text: "Nome Paciente",
          align: "left",
          sortable: true,
          value: "paciente.nome",
        },
        {
          text: "Data Consulta",
          align: "left",
          sortable: true,
          value: "dataConsulta",
        },
        {
          text: "CPF Paciente",
          align: "left",
          sortable: true,
          value: "paciente.cpf",
        },
        {
          text: "Unidade",
          align: "left",
          sortable: true,
          value: "unidade.nome",
        },
        {
          text: "Status",
          align: "left",
          sortable: true,
          value: "statusAgendamento.descricao",
        },
        {
          text: "Ação",
          align: "left",
          sortable: false,
          value: "acao",
        },
      ],
      pacientesLink: [],
      headersPaciente: [
        {
          text: "Paciente",
          align: "left",
          sortable: false,
          value: "paciente",
        },
        {
          text: "Idade",
          align: "left",
          sortable: false,
          value: "idade",
        },
        {
          text: "Tipo",
          align: "left",
          sortable: false,
          value: "tipo",
        },
        {
          text: "Data",
          align: "left",
          sortable: false,
          value: "data",
        },
        {
          text: "Unidade",
          align: "left",
          sortable: false,
          value: "unidade",
        },
      ],
    };
  },
  components: {
    Alerta,
    RegistroPreAtendimentoComponent,
  },
  computed: {
    ...mapGetters([
      "agendamentoQuery", 
      "agendamentoRefreshList",
      "agendamentoLastItemId"
    ]),

    filteredItems() {
      console.log("filteredItems");
      return this.items;
    },
    possuiPermissaoAdmin() {
      return possuiPermissao(["ROLE_ADMIN", "ROLE_CLIENTE_ADMIN"]);
    },
    possuiPermissaoAtendente() {
      return possuiPermissao(["ROLE_ATENDENTE"]);
    },
  },
  watch: {
    start_dateFormatada() {
      if (this.start_dateFormatada){
        this.start_date = moment(this.start_dateFormatada, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
      }
    },
    end_dateFormatada() {
      if (this.end_dateFormatada){
        this.end_date = moment(this.end_dateFormatada, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
      }
    },
    search() {
      this.setAgendamentoQuery({ query: "" /*this.filters*/ });
    },
    options: {
      handler() {
        this.setFilterQuery();
      },
      deep: true,
    },
    refreshList(value) {
      if (value) {
        this.refreshInterval = setInterval(() => {
          this.pesquisar();
        }, 60 * 1000);
      } else {
        clearInterval(this.refreshInterval);
      }
      // Altera o estado da variável agendamentoRefreshList (vuex)
      this.setAgendamentoRefreshList(value);
    },
  },
  methods: {
    ...mapActions([
      "setUltimaTela",
      "setAlerta",
      "setAgendamentoQuery",
      "setAgendamentoRefreshList",
      "setAgendamentoLastItemId",
    ]),
    changeFavorite(){
      this.isFavorited = false;
    },
    isDesrmarcarFavorito(favo){
      return favo.active === "ml-1 mb-1 pr-4 elevation-0";
    },
    desmascarFavoritos(){
      this.favoritos.forEach(lf =>{
          lf.active = "ml-1 mb-1 pr-4 elevation-2 teal white--text";
      });
    },
    selecionarFavorito(favo){
      this.favoritos.forEach(lf =>{
          if (lf.id === favo.id){
              lf.active = "ml-1 mb-1 pr-4 elevation-0";
          }else if (lf.active === "ml-1 mb-1 pr-4 elevation-0"){
            lf.active = "ml-1 mb-1 pr-4 elevation-2 teal white--text";
          }

      });
    },
    executarFavorito(favo){
      var self = this;
      
      if (this.isDesrmarcarFavorito(favo)){
        
        this.desmascarFavoritos();
        this.isFavorited = false;
        this.limpar();
        this.clicouLimpar = false;
        this.setFilterQuery();

      }else{
        this.filtroPesquisa = Object.assign({}, JSON.parse(favo.valor) );
        this.favoritoAtual = Object.assign({}, favo);//Object.assign({}, self.filtroPesquisa);
        this.setarDataCorrespondente(self.filtroPesquisa.periodo);
        this.start_date = moment(self.start_dateFormatada, "YYYY-MM-DD").format("DD/MM/YYYY");
        this.end_date = moment(self.end_dateFormatada, "YYYY-MM-DD").format("DD/MM/YYYY");
        this.pesquisar();
        this.isFavorited = true;
        this.selecionarFavorito(favo);
      }
      
    },
    removerFavoritosFiltroPesquisa() {
      var self = this;
      this.favoritoAtual.situacao = false;
      this.$http
        .put("/favorito", this.favoritoAtual)
        .then(() => {
          self.isFavorited = false;
          self.buscarFavoritos();
          self.setAlerta({
            tipoAlerta: "success",
            textAlerta: "Filtro removido dos favoritos com sucesso!",
          });
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta:
              "Ocorreu um erro ao remover o filtro dos favoritos. Por favor, contate o Administrador!",
          });
        });
    },
    openDialogFavoritarFiltroPesquisa() {
      var self = this;
      self.dialogFavorito=true;
    },
    favoritarFiltroPesquisa() {
      var self = this;
      
      var favorito = {
        "tipo": "MZW2_AGENDAMENTO_LIST",
        "nome": self.favoritoForm.nome,
        "valor": JSON.stringify(Object.assign({}, self.filtroPesquisa))
      }
      console.log(JSON.stringify(favorito))
      this.$http
        .post("/favorito", favorito)
        .then((response) => {
          console.log('RESPOSTA SALVAR FAVORITO')
          console.log(response)
          console.log('RESPOSTA SALVAR FAVORITO')
          self.dialogFavorito=false;
          self.isFavorited = true;
          self.favoritoAtual = Object.assign({}, response.data);
          self.buscarFavoritos();
          self.setAlerta({
            tipoAlerta: "success",
            textAlerta: "Filtro favoritado com sucesso!",
          });
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta:
              "Ocorreu um erro ao favoritar o filtro. Por favor, contate o Administrador!",
          });
        });
    },
    changeStatusFilterBottom(value) {
      this.filtroPesquisa.statusPacienteForm = value.map(
        (v) => this.statuss.filter((s) => s.id == v + 2)[0]
      );
      this.pesquisar();
    },
    updateStatusFilterBottom() {
      // Atualiza os botões statusFilterBottom
      if (this.filtroPesquisa.statusPacienteForm) {
        this.statusFilterBottom = this.filtroPesquisa.statusPacienteForm.map((s) => s.id - 2);
      }else{
        this.statusFilterBottom.splice(0,this.statusFilterBottom.length)
      }
    },
    periodoDeTempoChange(periodoChange){
      this.setarDataCorrespondente(periodoChange);
      this.$emit('changeFavorite')
    },
    setarDataCorrespondente(periodoChange) {
      if (!periodoChange) periodoChange = { id: "DEFAULT" };

      if (periodoChange.id === "PROXIMOS_30_DIAS") {
        this.start_dateFormatada = moment().format("YYYY-MM-DD");
        this.end_dateFormatada = moment().add(30, "days").format("YYYY-MM-DD");
      } else if (periodoChange.id === "PROXIMOS_07_DIAS") {
        this.start_dateFormatada = moment().format("YYYY-MM-DD");
        this.end_dateFormatada = moment().add(7, "days").format("YYYY-MM-DD");
      } else if (periodoChange.id === "HOJE") {
        this.start_dateFormatada = moment().format("YYYY-MM-DD");
        this.end_dateFormatada = moment().format("YYYY-MM-DD");
      } else if (periodoChange.id === "AMANHA") {
        this.start_dateFormatada = moment().add(1, "days").format("YYYY-MM-DD");
        this.end_dateFormatada = moment().add(1, "days").format("YYYY-MM-DD");
      } else if (periodoChange.id === "ONTEM") {
        this.start_dateFormatada = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        this.end_dateFormatada = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (periodoChange.id === "ULTIMOS_03_DIAS") {
        this.start_dateFormatada = moment()
          .subtract(3, "days")
          .format("YYYY-MM-DD");
        this.end_dateFormatada = moment().format("YYYY-MM-DD");
      } else if (periodoChange.id === "ULTIMOS_07_DIAS") {
        this.start_dateFormatada = moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD");
        this.end_dateFormatada = moment().format("YYYY-MM-DD");
      } else if (periodoChange.id === "ULTIMOS_30_DIAS") {
        this.start_dateFormatada = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.end_dateFormatada = moment().format("YYYY-MM-DD");
      } else if (periodoChange.id === "DEFAULT") {
        this.start_dateFormatada = moment()
          .subtract(10, "days")
          .format("YYYY-MM-DD");
        this.end_dateFormatada = moment().add(10, "days").format("YYYY-MM-DD");
      }
    },
    triggerRegistroPreAtendimentoComponent(p_registropreAtendimento, evento) {
      console.log("triggerRegistroPreAtendimentoComponent");
      console.log(evento);
      console.log(p_registropreAtendimento);
      Object.assign(this.registroPreAtendimentoComponentOpts, {
        showPreAtendimentoForm: false,
        dialogPreAtendimento: false,
        lastPreAtendimentoEvent: evento,
        lastPreAtendimentoDados: p_registropreAtendimento,
      });
      console.log("-");

      if (p_registropreAtendimento) {
        console.log({
          id: p_registropreAtendimento.agendamento.id,
          statusAgendamento: { descricao: p_registropreAtendimento.status },
        });
        console.log(this.items);

        var index = this.items.findIndex(
          (obj) => obj.id == p_registropreAtendimento.agendamento.id
        );

        this.items[index].statusAgendamento = this.makeStatusObj(
          p_registropreAtendimento.status
        );
      }
    },
    linhaClicada(val) {
      var self = this;
      console.log("linhaclicada");
      console.log(val);

      self.agendamentoSelecionado = Object.assign({}, val);
      console.log(self.agendamentoSelecionado);

      // Altera o estado da variável de controle do ultimo item acessado.
      this.setAgendamentoLastItemId(val.id)

      this.$http
        .get(`/registroPreAtendimento?agendamentoId=${val.id}`)
        .then((response) => {
          console.log(response);

          if (response.data.totalSize > 0) {
            self.agendamentoSelecionado.preAtendimento = Object.assign(
              {},
              { id: response.data.content[0].id }
            );
          } else {
            self.agendamentoSelecionado.preAtendimento = {};
          }

          self.registroPreAtendimentoComponentOpts.showPreAtendimentoForm = true;
          self.registroPreAtendimentoComponentOpts.dialogPreAtendimento = true;
        })
        .catch((e) => {
          console.log("erro");
          console.log(e);
          Promise.reject(
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: !e.response
                ? "Connection Refused!"
                : e.response.data.message,
            })
          );
        });
    },
    getIdadeByDataAniversario() {
      var today = new Date();
      var birthDate = new Date(
        this.atendimentoSelecionado.registroPreAtendimento.agendamento.paciente.dataNascimento
      );
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    linkTeleconsulta(atendimento, agendamentoId) {
      var self = this;

      self.atendimentoSelecionado = Object.assign({}, atendimento);
      console.log(self.atendimentoSelecionado);

       // Altera o estado da variável de controle do ultimo item acessado.
      if(agendamentoId) {
        this.setAgendamentoLastItemId(agendamentoId)
      }

      if (
        self.atendimentoSelecionado.linkTeleConsultaPaciente != null ||
        self.atendimentoSelecionado.linkTeleConsultaMedico != null
      ) {
        console.log("### ABRIR TELA DE LINK TELECONSULTA");
        console.log(self.atendimentoSelecionado.linkTeleConsultaPaciente);
        console.log(self.atendimentoSelecionado.linkTeleConsultaMedico);

        self.pacientesLink = Object.assign(
          [{}],
          [
            {
              paciente:
                self.atendimentoSelecionado.registroPreAtendimento.agendamento
                  .paciente.nome,
              idade: self.getIdadeByDataAniversario(),
              tipo: self.atendimentoSelecionado.registroPreAtendimento
                .agendamento.tipoConsulta
                ? self.atendimentoSelecionado.registroPreAtendimento.agendamento
                    .tipoConsulta.nome
                : "",
              data: self.formatDataConsulta(
                `${self.atendimentoSelecionado.registroPreAtendimento.agendamento.dataConsulta}Z`
              ),
              unidade:
                self.atendimentoSelecionado.registroPreAtendimento.agendamento
                  .unidade.nome,
            },
          ]
        );

        self.linkTeleConsultaComponent.showLinkTeleConsulta = true;
        self.linkTeleConsultaComponent.dialogLinkTeleConsulta = true;
      } else {
        console.log("### NÃO DEU CERTO...");
        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Não foram encontrados links para teleconsulta!",
        });
      }
    },
    formatDataConsulta(data) {
      let dataConsulta = this.$moment(data, "YYYY-MM-DDTHH:mm:ss");
      let _now = this.$moment();

      let dataConsultaAux = dataConsulta.format("YYYY-MM-DD");
      let dateNowAux = _now.format("YYYY-MM-DD");

      if (dataConsultaAux == dateNowAux) {
        return dataConsulta.locale('pt-br').format("HH:mm");
      } else {
        return dataConsulta.locale('pt-br').format('DD MMM');
      }
    },
    formatDataConsultaDisplay (dataConsulta) {
      return moment(dataConsulta).locale("pt-br").format("HH:mm")
    },
    isDateToday (date) {
      return moment().format("DD/MM/YYYY") == moment(date).format("DD/MM/YYYY")
    },

    excluir() {
      var self = this;
      Object.keys(this.selected).forEach((index) => {
        const selecionado = this.selected[index];

        this.$http
          .delete(`/agendamento/${selecionado.id}`)
          .then((response) => {
            if (response.status == "202") {
              self.setAlerta({
                tipoAlerta: "error",
                textAlerta: response.data.message,
              });
            } else {
              self.atualizarLista();
              self.setAlerta({
                tipoAlerta: "success",
                textAlerta: "Usuário excluído com sucesso",
              });
            }
          })
          .catch((error) => {
            Promise.reject(
              self.setAlerta({
                tipoAlerta: "error",
                textAlerta: !error.response
                  ? "Connection Refused!"
                  : error.response.data.message,
              })
            );
          });

        setTimeout(function () {
          self.selected = [];
        }, 3000);

        this.dialog = false;
      });
    },
    novo() {
      this.$router.push({ name: "AgendamentoCreate" });
    },
    editar() {
      var index = Object.keys(this.selected)[0];
      const selecao = this.selected[index];

      // Altera o estado da variável de controle do ultimo item acessado.
      this.setAgendamentoLastItemId(selecao.id)

      this.$router.push({
        name: "AgendamentoEdit",
        params: { id: selecao.id },
      });
    },
    limparClickFilter(){
      this.limpar();
      this.pesquisar();
    },
    limpar() {
      this.clicouLimpar = true;
      bus.$emit("clickSearchFilter", "");
      this.periodo = null;
      this.show_start_date = null;
      this.start_date = null;
      this.show_end_date = null;
      this.end_date = null;
      this.cpfPacienteForm = "";
      this.nomePacienteElemento = "";
      this.statusPacienteForm = "";
      this.unidadePacienteForm = "";
      this.filtroPesquisa = {};
      this.filtroPesquisa.status = [];
      this.filtroPesquisa.statusPacienteForm = [];
      window.sessionStorage.removeItem("start_date"+ `_${this.$instanceId}`);
      window.sessionStorage.removeItem("end_date"+ `_${this.$instanceId}`);
      window.sessionStorage.removeItem("atendimentoFilterStorage"+ `_${this.$instanceId}`);
      window.sessionStorage.removeItem("unidadePacienteForm"+ `_${this.$instanceId}`)  ;
      window.sessionStorage.removeItem("statusPacienteForm"+ `_${this.$instanceId}`);
      this.desmascarFavoritos();
      this.isFavorited = false;
      this.statusFilterBottom.splice(0,this.statusFilterBottom.length)
    },
    pesquisar() {
      this.options.page = 1;

      let txtFilter = ``;

      
      if (this.start_date) {
        txtFilter += ` =dataInicial:${this.start_date}`;
        window.sessionStorage.setItem("start_date"+ `_${this.$instanceId}`, this.start_date);
      } else {
        window.sessionStorage.removeItem("start_date"+ `_${this.$instanceId}`);
      }

      if (this.end_date) {
        txtFilter += ` =dataFinal:${this.end_date}`;
        window.sessionStorage.setItem("end_date"+ `_${this.$instanceId}`, this.end_date);
      } else {
        window.sessionStorage.removeItem("end_date"+ `_${this.$instanceId}`);
      }

      if (this.filtroPesquisa.nomePacienteElemento &&  this.filtroPesquisa.nomePacienteElemento != "") {
        txtFilter += ` =nomePaciente:${this.filtroPesquisa.nomePacienteElemento}`;
        window.sessionStorage.setItem(
          "nomePacienteElemento"+ `_${this.$instanceId}`,
          this.filtroPesquisa.nomePacienteElemento
        );
      } else {
        window.sessionStorage.removeItem("nomePacienteElemento"+ `_${this.$instanceId}`);
      }

      if (this.filtroPesquisa.cpfPacienteForm && this.filtroPesquisa.cpfPacienteForm != "") {
        txtFilter += ` =cpfPaciente:${this.filtroPesquisa.cpfPacienteForm}`;
        window.sessionStorage.setItem("cpfPacienteForm"+ `_${this.$instanceId}`, this.filtroPesquisa.cpfPacienteForm);
      } else {
        window.sessionStorage.removeItem("cpfPacienteForm"+ `_${this.$instanceId}`);
      }

      if (this.filtroPesquisa.unidadePacienteForm && this.filtroPesquisa.unidadePacienteForm != "") {
        txtFilter += ` =unidadeId:${this.filtroPesquisa.unidadePacienteForm
          .map(function (k) {
            console.log(k);
            return k.nome;
          })
          .join(",")}`;
        window.sessionStorage.setItem(
          "unidadePacienteForm"+ `_${this.$instanceId}`,
          JSON.stringify(this.filtroPesquisa.unidadePacienteForm)
        );
      } else {
        window.sessionStorage.removeItem("unidadePacienteForm"+ `_${this.$instanceId}`);
      }

      if (this.filtroPesquisa.statusPacienteForm && this.filtroPesquisa.statusPacienteForm != "") {
        txtFilter += ` =listaStatus:${this.filtroPesquisa.statusPacienteForm
          .map(function (k) {
            console.log(k);
            return k.nome;
          })
          .join(",")}`;
        window.sessionStorage.setItem(
          "statusPacienteForm"+ `_${this.$instanceId}`,
          JSON.stringify(this.filtroPesquisa.statusPacienteForm)
        );
      } else {
        window.sessionStorage.removeItem("statusPacienteForm"+ `_${this.$instanceId}`);
      }

      bus.$emit("clickSearchFilter", txtFilter);

      this.atualizarLista();
      this.dialogTeste = false;
    },
    setFilterQuery() {
      console.log('setFilterQuery')
      let txtFilter = ``;

      this.start_date = window.sessionStorage.getItem("start_date"+ `_${this.$instanceId}`);
      this.end_date = window.sessionStorage.getItem("end_date"+ `_${this.$instanceId}`);

      console.log(this.start_date == null && this.end_date == null)


      if (this.start_date == null && this.end_date == null){
        if (this.clicouLimpar == false){
          this.setarDatasPadrao();
        }
      }
    
      if (this.start_date) {
        this.start_dateFormatada = moment(this.start_date, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        );
        txtFilter += ` =dataInicial:${this.start_date}`;
      }

      
      if (this.end_date) {
        this.end_dateFormatada = moment(this.end_date, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        );
        txtFilter += ` =dataFinal:${this.end_date}`;
      }

      this.filtroPesquisa.nomePacienteElemento = window.sessionStorage.getItem(
        "nomePacienteElemento"+ `_${this.$instanceId}`
      );
      this.filtroPesquisa.nomePacienteElemento =
        this.filtroPesquisa.nomePacienteElemento == null ? "" : this.filtroPesquisa.nomePacienteElemento;
      if (this.filtroPesquisa.nomePacienteElemento != "") {
        txtFilter += ` =nomePaciente:${this.filtroPesquisa.nomePacienteElemento}`;
      }

      this.filtroPesquisa.cpfPacienteForm = window.sessionStorage.getItem("cpfPacienteForm"+ `_${this.$instanceId}`);
      this.filtroPesquisa.cpfPacienteForm =
        this.filtroPesquisa.cpfPacienteForm == null ? "" : this.filtroPesquisa.cpfPacienteForm;
      if (this.filtroPesquisa.cpfPacienteForm != "") {
        txtFilter += ` =cpfPaciente:${this.filtroPesquisa.cpfPacienteForm}`;
      }

      if (window.sessionStorage.getItem("unidadePacienteForm"+ `_${this.$instanceId}`) && window.sessionStorage.getItem("unidadePacienteForm"+ `_${this.$instanceId}`) != null) {
        this.unidadePacienteForm = JSON.parse(
          window.sessionStorage.getItem("unidadePacienteForm"+ `_${this.$instanceId}`)
        );
        try{
        txtFilter += ` =unidadeId:${this.filtroPesquisa.unidadePacienteForm
          .map(function (k) {
            console.log(k);
            return k.nome;
          })
          .join(",")}`;
        }catch(e){ console.log(e) }
      }

      if (window.sessionStorage.getItem("statusPacienteForm") && window.sessionStorage.getItem("statusPacienteForm"+ `_${this.$instanceId}`) != null) {
        this.filtroPesquisa.statusPacienteForm = JSON.parse(
          window.sessionStorage.getItem("statusPacienteForm" + `_${this.$instanceId}`)
        );
        try{
        txtFilter += ` =listaStatus:${this.filtroPesquisa.statusPacienteForm
          .map(function (k) {
            console.log(k);
            return k.nome;
          })
          .join(",")}`;
        }catch(e){ console.log(e) }
      }

      bus.$emit("clickSearchFilter", txtFilter);

      this.atualizarLista();
      this.dialogTeste = false;
    },
    atualizarLista() {
      this.loading = true;
      var self = this;

      // Atualiza os botões statusFilterBottom
      this.updateStatusFilterBottom()

      console.log("**********************");

      console.log(this.start_date);
      console.log(this.end_date);

      const { page, itemsPerPage, sortBy, sortDesc } = this.options;

      var offset = page - 1;
      let order = sortDesc[0] ? "desc" : "asc";
      let sort = sortBy.length > 0 ? sortBy[0] : "id";
      let url = `/agendamento?`;

      if (itemsPerPage) {
        url += `max=${itemsPerPage}`;
      } else {
        url += `max=10`;
      }

      if (offset) {
        url += `&offset=${offset}`;
      }

      if (sort) {
        url += `&sort=${sort}&order=${order}`;
      }

      if (this.start_date) {
        url += `&dataDe=${this.start_dateFormatada}`;
      }

      if (this.end_date) {
        url += `&dataAte=${this.end_dateFormatada}`;
      }

      if (this.filtroPesquisa.nomePacienteElemento && this.filtroPesquisa.nomePacienteElemento != "") {
        url += `&nomePaciente=${this.filtroPesquisa.nomePacienteElemento}`;
      }

      if (this.filtroPesquisa.cpfPacienteForm && this.filtroPesquisa.cpfPacienteForm != "") {
        url += `&cpfPaciente=${this.filtroPesquisa.cpfPacienteForm}`;
      }

      if (this.filtroPesquisa.unidadePacienteForm && this.filtroPesquisa.unidadePacienteForm != "") {
        url += `&unidadeId=${this.filtroPesquisa.unidadePacienteForm
          .map(function (k) {
            console.log(k);
            return k.id;
          })
          .join(",")}`;
      }

      if (this.filtroPesquisa.statusPacienteForm && this.filtroPesquisa.statusPacienteForm != "") {
        url += `&listaStatus=${this.filtroPesquisa.statusPacienteForm
          .map(function (k) {
            console.log(k);
            return k.id;
          })
          .join(",")}`;
      }

      if (
        !this.filtroPesquisa.nomePacienteForm || this.filtroPesquisa.nomePacienteForm == "" &&
        !this.filtroPesquisa.cpfPacienteForm || this.filtroPesquisa.cpfPacienteForm == "" &&
        !this.filtroPesquisa.unidadePacienteForm || this.filtroPesquisa.unidadePacienteForm == "" &&
        !this.filtroPesquisa.statusPacienteForm || this.filtroPesquisa.statusPacienteForm == ""
      ) {
        url = `${url}`;
      }

      console.log(url);
      url += "&withAtendimentoIfExists=true";
      this.$http
        .get(url)
        .then((response) => {
          console.log(response);
          this.items = response.data.content;
          this.total = response.data.totalSize;
          this.options.itemsPerPage = response.data.size;
          this.loading = false;
          bus.$emit("last-rest-call", self.getPreferenceParameter(url));
        })
        .catch((e) => {
          Promise.reject(
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: !e.response
                ? "Connection Refused!"
                : e.response.data.message,
            })
          );
        });
    },

    buscarUnidades() {
      var self = this;
      this.$http
        .get(`/unidade?max=-1`)
        .then((response) => {
          this.unidades = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar unidades",
          });
        });
    },

    intervalFormat(interval) {
      return interval.time;
    },
    buscarFavoritos() {
      var self = this;
      this.$http
        .get(`/favorito`)
        .then((response) => {
          this.favoritos = response.data.filter(f => f.situacao === true && f.tipo === "MZW2_AGENDAMENTO_LIST");
          this.favoritos.forEach(f => {
                f.active = "ml-1 mb-1 pr-4 elevation-2 teal white--text";
          });
          
          if (self.favoritoAtual){
            self.selecionarFavorito(self.favoritoAtual);
          }

        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Favoritos",
          });
        });
    },
    buscarPacientes() {
      this.loadingPaciente = true;
      var self = this;
      console.log("chamando paciente");
      this.$http
        .get(`/paciente?&max=50`)
        .then((response) => {
          console.log("VOLTOU");
          console.log("response");
          this.pacientes = response.data.content;
          this.pacientesUnmodified = Object.assign([{}], this.pacientes);
          this.loadingPaciente = false;
        })
        .catch(() => {
          this.loadingPaciente = false;
          console.log("ERRO");
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Pacientes",
          });
        });
    },
    statusColor(status) {
      return status === "PENDENTE"
        ? "orange"
        : status == "CANCELADO"
        ? "red"
        : status == "CONFIRMADO"
        ? "green"
        : "primary";
    },
    makeStatusObj(status) {
      return status == "PENDENTE"
        ? { id: 2, descricao: "PENDENTE" }
        : status == "CANCELADO"
        ? { id: 4, descricao: "CANCELADO" }
        : status == "CONFIRMADO"
        ? { id: 3, descricao: "CONFIRMADO" }
        : status == "ATENDIDO"
        ? { id: 5, descricao: "ATENDIDO" }
        : { id: 1, descricao: "AGENDADO" };
    },
    globalFilterClicked() {
      console.log("Cliacdo no Filtro No componente de Layout");

      if (this.filtroPesquisa.periodo){
        this.setarDataCorrespondente(this.filtroPesquisa.periodo);
      }

      this.dialogTeste = true;
    },
    setarDatasPadrao() {
      this.start_dateFormatada = moment()
        .subtract(10, "days")
        .format("YYYY-MM-DD");
      this.start_date = moment(this.start_dateFormatada, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );

      this.end_dateFormatada = moment().add(10, "days").format("YYYY-MM-DD");
      this.end_date = moment(this.end_dateFormatada, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );
    },
    getPreferenceParameter(valor) {
      return {
        componentName: this.$options.name,
        value: valor,
        options: this.options,
      };
    },
    setTelaUserPreferences() {
      var userPreferenceStored = getUserPreferences(this.$options.name);

      if (userPreferenceStored && userPreferenceStored["options"]) {
        this.options = userPreferenceStored["options"];
      }
    },
    fecharDialogFiltroPesquisa() {
      console.log("fecharDialogFiltroPesquisa");
      this.dialogTeste = false;
    },

    isAgendamentoLastItemId(itemId) {
      return this.agendamentoLastItemId === itemId;
    },
    getRowClassItem (item) {
      let className = "row-class-item";

      // Retorna classe para agendamentos agendados.
      if(item.statusAgendamento.descricao == "PENDENTE") {
        className += " row-class-item-status-pendente"
      }
      
      // Retorna classe para agendamentos atendidos.
      if(item.statusAgendamento.descricao == "ATENDIDO") {
        className += " row-class-item-status-atendido"
      }
      
      // Retorna classe para agendamentos cancelados.
      if(item.statusAgendamento.descricao == "CANCELADO") {
        className += " row-class-item-status-cancelado"
      }

      // Retorna classe do último registro de agendamento visitado.
      if(this.isAgendamentoLastItemId(item.id)) {
        className += " row-class-item-last-visitor";
      }
      return className;
    }
  },

  filters: {
    /**
     * Format a timestamp into a d/m/yyy (because spanish format).
     *
     * @param value
     * @returns {string}
     */
    formatDate: function (value) {
      if (!value) return "";
      return new Date(value).toLocaleDateString("es-ES");
    },
  },

  created() {
    console.log("Agendamento  - created");
    this.setUltimaTela({ tela: this.$route.fullPath });

    this.search = this.agendamentoQuery;
    // Carrega a variável a partir do atributo de estado do vuex (computed)
    this.refreshList = this.agendamentoRefreshList;

    this.setTelaUserPreferences();
    this.buscarUnidades();
    this.buscarFavoritos();

    bus.$on("clickFilter", this.globalFilterClicked);
    bus.$on("limparClickFilter", this.limparClickFilter);
    this.$on("changeFavorite", this.changeFavorite);
  },
  beforeMount() {},
  mounted() {
    bus.$emit("showFilterComponent");
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
    bus.$emit("hideFilterComponent");
  },
  destroyed() {
    bus.$emit("clickSearchFilter", "");
  },
};
</script>

<style scoped>
::v-deep .my-custom-dialog {
  align-self: flex-start;
  margin-top: 65px;
}

/* CSS para controle da exibição dos itens do v-data-table com id:  agendamento-table-item */
::v-deep #agendamento-table-item .row-class-item {
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
}
::v-deep #agendamento-table-item .row-class-item:hover {
  box-shadow: 
    inset 1px 0px 0px #dadce0, 
    inset -1px 0px 0px #dadce0, 
    0px 1px 2px 0px rgba(60, 64, 67, 30%), 
    0px 1px 3px 1px rgba(60, 64, 67, 15%) !important;
  z-index: 2;
  background-color: inherit;
}
::v-deep .theme--dark #agendamento-table-item .row-class-item:hover {
  box-shadow: 
    inset 1px 0px 0px rgb(255, 255, 255, 20%), 
    inset -1px 0px 0px rgba(255, 255, 255, 20%), 
    0px 0px 4px 0px rgba(95, 99, 104, 60%), 
    0px 0px 6px 2px rgba(95, 99, 104, 60%) !important;
  z-index: 2;
  background-color: inherit;
}
::v-deep #agendamento-table-item .row-class-item a:hover {
  text-decoration: underline;
}
::v-deep #agendamento-table-item .row-class-item .mdi-checkbox-blank-outline {
  opacity: 0.3;
}
::v-deep #agendamento-table-item .row-class-item:hover .mdi-checkbox-blank-outline {
  opacity: 1;
}
::v-deep #agendamento-table-item .row-class-item #item-actions-botton {
  opacity: 0.3;
}
::v-deep #agendamento-table-item .row-class-item:hover #item-actions-botton {
  opacity: 1;
}
::v-deep #agendamento-table-item .row-class-item-status-pendente {
  font-weight: bold;
}
::v-deep #agendamento-table-item .row-class-item-status-atendido {
  background-color: rgba(242, 245, 245, 0.8) !important;
}
::v-deep .theme--dark #agendamento-table-item .row-class-item-status-atendido {
  background-color: rgba(242, 245, 245,0.2) !important;
}
::v-deep #agendamento-table-item .row-class-item-status-cancelado {
  font-weight: 300;
}
::v-deep #agendamento-table-item .row-class-item-last-visitor {
  box-shadow: inset 0px 0px 0px 2px rgba(112, 191, 115, 0.80);
}

</style>